<script setup lang="ts">

import BackToTop from './components/aaid/BackToTop.vue'
import { useRoute } from 'vue-router';
import Login from './components/Login.vue';
import { getCookie } from './shared/helpers/cookieHelpers.ts'
import Register from './components/Register.vue';
import ForgotPassword from './components/ForgotPassword.vue';
import { onMounted, ref } from 'vue';
import MeService from './shared/services/MeService';
import useUserStore from './stores/useUserStore';
import ToastMessage from './components/aaid/ToastMessage.vue';
import SideSocial from './components/aaid/SideSocial.vue';
import AppLayout from './layouts/AppLayout.vue';
import { bootstrap } from 'vue-gtag';
const route = useRoute()
const meService = MeService.getInstance()
const userStore = useUserStore()
const showSocial = ref(false)
onMounted(async () => {
  if (getCookie('access_token')) {
    const user = await meService.get()
    if (user && user.data) {
      userStore.setUser(user.data)
      userStore.setAuthenticated(true)
    }
  }
  if (!(window.location.host.includes('localhost') || window.location.host.includes('127.0.0.1'))) {
    enablePlugin()
  }
  setTimeout(() => {
    showSocial.value = true
  }, 30000)
})
const enablePlugin = () => {
  bootstrap().then(() => { })
}
const showbackground: any = ref(false);
const showbg = () => {
  showbackground.value = true
  setTimeout(() => {
    showbackground.value = false
  }, 100)
}
</script>

<template>
  <div class="w-full" :class="{ 'bg-blue-100': showbackground }">
    <component :is="route.meta.layout || AppLayout">
      <RouterView @on-bg-show="showbg" />
    </component>
    <BackToTop />
    <Login />
    <Register />
    <ForgotPassword />
    <ToastMessage />
    <SideSocial v-if="showSocial" />
    <div class="trustpilot-widget mt-2" data-locale="en-US" data-template-id="56278e9abfbbba0bdcd568bc"
      data-businessunit-id="66e62e2c4358e36e3659e17e" data-style-height="52px" data-style-width="100%">
      <a href="https://www.trustpilot.com/review/audioaidynamics.com" target="_blank" rel="noopener">Trustpilot</a>
    </div>
  </div>
</template>

<style scoped></style>
