export const HomeMetaTags = [{
    name: 'keywords',
    content: "music ai,ai for music,song ai,music with ai,ai for musicians,music production programs,music producers,bpm finder,key finder,digital audio workstation,audio trimmer,voice recorder,find bpm,bpm tapper,normalize audio,online metronome,camelot"
}, {
    name: 'description',
    content: 'Key, Bpm, Camelot & Mood Finder,Bpm Tapper,Online Metronome,HPCP Chroma'
}, {
    itemprop: 'name',
    content: 'FREE Online audio AI tools'
}, {
    itemprop: 'description',
    content: 'Key, Bpm, Camelot & Mood Finder,Bpm Tapper,Online Metronome,HPCP Chroma,Audio Trimmer'
}, {
    itemprop: 'image',
    content: 'https://audioaidynamics.com/aaid-logo-og.jpg'
}, {
    property: 'og:type',
    content: 'website'
}, {
    property: 'og:title',
    content: 'FREE Online audio AI tools'
}, {
    property: 'og:site_name',
    content: 'Audio AI Dynamics'
}, {
    property: 'og:url',
    content: 'https://audioaidynamics.com/'
}, {
    property: 'og:description',
    content: 'Key,Bpm, Camelot & Mood Finder,Bpm Tapper,Online Metronome,HPCP Chroma,Audio Trimmer'
}, {
    property: 'og:image',
    content: 'https://audioaidynamics.com/aaid-logo-og.jpg'
}, {
    name: 'twitter:title',
    content: 'FREE Online audio AI tools'
}, {
    name: 'twitter:card',
    content: 'summary_large_image'
}, {
    name: 'twitter:description',
    content: 'Key, Bpm, Camelot & Mood Finder,Bpm Tapper,Online Metronome,HPCP Chroma,Audio Trimmer'
}, {
    name: 'twitter:image',
    content: 'https://audioaidynamics.com/aaid-logo-tw.jpg'
}]
export const ContactMetaTags = [{
    name: 'keywords',
    content: 'contact us,get in touch,audioaidynamics contact,contact audioaidynamics,aaid support,audioaidynamics support,audioaidynamics help,aaid contact page,audioaidynamics customer service,aaid inquiries,contact audio aaid'
}, {
    "name": "description",
    "content": "Get in touch with Audio AI Dynamics. Contact us today for assistance with your audio needs."
}, {
    "itemprop": "name",
    "content": "Contact Audio AI Dynamics"
}, {
    "itemprop": "description",
    "content": "Reach out to Audio AI Dynamics. Our team is ready to assist you with top-notch support and services."
}, {
    "itemprop": "image",
    "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
}, {
    "property": "og:type",
    "content": "website"
}, {
    "property": "og:title",
    "content": "Get in Touch with Audio AI Dynamics"
}, {
    "property": "og:site_name",
    "content": "Audio AI Dynamics"
}, {
    "property": "og:url",
    "content": "https://audioaidynamics.com/contact"
}, {
    "property": "og:description",
    "content": "Contact Audio AI Dynamics for professional audio consulting and solutions tailored to your needs. Visit our contact page for more information."
}, {
    "property": "og:image",
    "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
}, {
    "name": "twitter:title",
    "content": "Contact Audio AI Dynamics - Expert Audio Solutions"
}, {
    "name": "twitter:card",
    "content": "summary_large_image"
}, {
    "name": "twitter:description",
    "content": "Need professional audio advice or services? Contact Audio AI Dynamics today through our contact page. We're here to help you with all your audio needs."
}, {
    "name": "twitter:image",
    "content": "https://audioaidynamics.com/aaid-logo-tw.jpg"
}]

export const FaqMetaTags = [{
    name: 'keywords',
    content: 'FAQ,frequently asked questions,audioaidynamics FAQ,aaid FAQ,audioaidynamics help,audioaidynamics support,aaid support,audioaidynamics common questions,aaid frequently asked questions,audioaidynamics info,aaid help page'
}, {
    "name": "description",
    "content": "Find answers to frequently asked questions about Audio AI Dynamics products and services. Visit our FAQ page for more information."
}, {
    "itemprop": "name",
    "content": "Audio AI Dynamics FAQs"
}, {
    "itemprop": "description",
    "content": "Discover quick answers to common queries about our audio solutions and services. Check out the FAQ section at Audio AI Dynamics."
}, {
    "itemprop": "image",
    "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
}, {
    "property": "og:type",
    "content": "website"
}, {
    "property": "og:title",
    "content": "Frequently Asked Questions - Audio AI Dynamics"
}, {
    "property": "og:site_name",
    "content": "Audio AI Dynamics"
}, {
    "property": "og:url",
    "content": "https://audioaidynamics.com/faq"
}, {
    "property": "og:description",
    "content": "Have questions about Audio AI Dynamics? Visit our FAQ page for answers to the most common questions about our audio products and customer service."
}, {
    "property": "og:image",
    "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
}, {
    "name": "twitter:title",
    "content": "Audio AI Dynamics FAQs - Learn More About Our Services"
}, {
    "name": "twitter:card",
    "content": "summary_large_image"
}, {
    "name": "twitter:description",
    "content": "Looking for answers? Check out the FAQ section at Audio AI Dynamics to get detailed information on our products and services."
}, {
    "name": "twitter:image",
    "content": "https://audioaidynamics.com/aaid-logo-tw.jpg"
}]

export const AnalyzerMetaTags = [{
    name: 'keywords',
    content: "song key finder,bpm and key finder,key finder,bpm's,bpm finder,bpm,song bpm,find bpm,beat per minute finder,camelot,camelot finder"
}, {
    "name": "description",
    "content": "Find Key, BPM, Camelot and mood of any song instantly. Simply upload your songs and harness the power of AI"
}, {
    "itemprop": "name",
    "content": "AI-Powered Key, BPM, Camelot and Mood Finder"
}, {
    "itemprop": "description",
    "content": "Detect the Key, BPM, Camelot and mood of any song instantly. Simply upload your songs and harness the power of AI"
}, {
    "itemprop": "image",
    "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
}, {
    "property": "og:type",
    "content": "website"
}, {
    "property": "og:title",
    "content": "AI-Powered Key, BPM, Camelot and Mood Finder"
}, {
    "property": "og:site_name",
    "content": "Audio AI Dynamics"
}, {
    "property": "og:url",
    "content": "https://audioaidynamics.com/music-analyzer"
}, {
    "property": "og:description",
    "content": "Find BPM, Key, Camelot and mood of any song instantly. Simply upload your songs and harness the power of AI"
}, {
    "property": "og:image",
    "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
}, {
    "name": "twitter:title",
    "content": "AI-Powered Key, BPM, Camelot and Mood Finder"
}, {
    "name": "twitter:card",
    "content": "summary_large_image"
}, {
    "name": "twitter:description",
    "content": "Find BPM, Key, Camelot and mood of any song instantly. Simply upload your songs and harness the power of AI"
}, {
    "name": "twitter:image",
    "content": "https://audioaidynamics.com/aaid-logo-tw.jpg"
}]

export const BpmTapperMetaTags = [{
    name: 'keywords',
    content: "bpm tapper,tap,bpm counter,tap tempo,tap bpm,tap beats per minute,tap to tempo,tempo tapper,find bpm,bpm finder"
}, {
    "name": "description",
    "content": "Easily find out the beats per minute (BPM) of any song with the FREE BPM Tapper Tool. Tap along to any track to find its tempo."
}, {
    "itemprop": "name",
    "content": "Interactive BPM Counter Tool - Tap to Find the Beat Instantly"
}, {
    "itemprop": "description",
    "content": "Easily find out the beats per minute (BPM) of any song with the FREE BPM Tapper Tool. Tap along to any track to find its tempo."
}, {
    "itemprop": "image",
    "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
}, {
    "property": "og:type",
    "content": "website"
}, {
    "property": "og:title",
    "content": "Interactive BPM Counter Tool - Tap to Find the Beat Instantly"
}, {
    "property": "og:site_name",
    "content": "Audio AI Dynamics"
}, {
    "property": "og:url",
    "content": "https://audioaidynamics.com/bpm-tapper"
}, {
    "property": "og:description",
    "content": "Easily find out the beats per minute (BPM) of any song with the FREE BPM Tapper Tool. Tap along to any track to find its tempo."
}, {
    "property": "og:image",
    "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
}, {
    "name": "twitter:title",
    "content": "Interactive BPM Counter Tool - Tap to Find the Beat Instantly"
}, {
    "name": "twitter:card",
    "content": "summary_large_image"
}, {
    "name": "twitter:description",
    "content": "Easily find out the beats per minute (BPM) of any song with the FREE BPM Counter Tool. Tap along to any track to find its tempo."
}, {
    "name": "twitter:image",
    "content": "https://audioaidynamics.com/aaid-logo-tw.jpg"
}]

export const HpcpChromaMetaTags = [{
    name: 'keywords',
    content: "hpcp chroma,real time,chromatic analysis,tune your instrument,real-time,hpcp,audio analysis,real time vocal analysis,voice training,vocal training,high pitch class profiles"
}, {
    "name": "description",
    "content": "Use HPCP Chroma Tool for real-time key accuracy checks, voice training and instrument tuning. Watch keys light up as you play or sing."
}, {
    "itemprop": "name",
    "content": "Voice Training with with Real-time HPCP Chroma Tool for Singers and Musicians"
}, {
    "itemprop": "description",
    "content": "Use HPCP Chroma Tool for real-time key accuracy checks, voice training and instrument tuning. Watch keys light up as you play or sing."
}, {
    "itemprop": "image",
    "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
}, {
    "property": "og:type",
    "content": "website"
}, {
    "property": "og:title",
    "content": "Voice Training with Real-time HPCP Chroma Tool for Singers and Musicians"
}, {
    "property": "og:site_name",
    "content": "Audio AI Dynamics"
}, {
    "property": "og:url",
    "content": "https://audioaidynamics.com/hpcp-chroma"
}, {
    "property": "og:description",
    "content": "Use HPCP Chroma Tool for real-time key accuracy checks, voice training and instrument tuning. Watch keys light up as you play or sing."
}, {
    "property": "og:image",
    "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
}, {
    "name": "twitter:title",
    "content": "Voice Training with Real-time HPCP Chroma Tool for Singers and Musicians"
}, {
    "name": "twitter:card",
    "content": "summary_large_image"
}, {
    "name": "twitter:description",
    "content": "Use HPCP Chroma Tool for real-time key accuracy checks, voice training and instrument tuning. Watch keys light up as you play or sing."
}, {
    "name": "twitter:image",
    "content": "https://audioaidynamics.com/aaid-logo-tw.jpg"
}]

export const MetronomeMetaTags = [{
    name: 'keywords',
    content: "online metronome,free metronome,free online metronome,digital metronome,metronome tool,metronome online,online metronome free,metronome,free metronome for musicians,best free metronome online"
}, {
    "name": "description",
    "content": "Keep perfect time with accurate online metronome tool. Easily set your tempo for practice, performance, or recording sessions."
}, {
    "itemprop": "name",
    "content": "Online Metronome Tool - Set Your Tempo with Precision"
}, {
    "itemprop": "description",
    "content": "Keep perfect time with accurate online metronome tool. Easily set your tempo for practice, performance, or recording sessions."
}, {
    "itemprop": "image",
    "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
}, {
    "property": "og:type",
    "content": "website"
}, {
    "property": "og:title",
    "content": "Online Metronome Tool - Set Your Tempo with Precision"
}, {
    "property": "og:site_name",
    "content": "Audio AI Dynamics"
}, {
    "property": "og:url",
    "content": "https://audioaidynamics.com/online-metronome"
}, {
    "property": "og:description",
    "content": "Keep perfect time with an accurate online metronome tool. Easily set your tempo for practice, performance, or recording sessions."
}, {
    "property": "og:image",
    "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
}, {
    "name": "twitter:title",
    "content": "Online Metronome Tool - Set Your Tempo with Precision"
}, {
    "name": "twitter:card",
    "content": "summary_large_image"
}, {
    "name": "twitter:description",
    "content": "Keep perfect time with an accurate online metronome tool. Easily set your tempo for practice, performance, or recording sessions."
}, {
    "name": "twitter:image",
    "content": "https://audioaidynamics.com/aaid-logo-tw.jpg"
}]

export const NoIndexMetaTags = [{
    name: 'robots',
    content: 'noindex'
}]

export const AudioTrimmerMetaTags = [
    {
        "name": 'keywords',
        "content": "Audio trimmer,Audio trimer,Audio cutter,Audio splitter,Mp3 cutter,Mp3 cut,Mp3 trimmer,cut mp3,Edit audio,Online audio trimmer,Trim song"
    }, {
        "name": "description",
        "content": "Audio Trimmer - free online tool for cutting and trimming any audio files. Upload your file and get started immediately!"
    }, {
        "itemprop": "name",
        "content": "Audio Trimmer | MP3 Cutter | Audio Cutter"
    }, {
        "itemprop": "description",
        "content": "Audio Trimmer - free online tool for cutting and trimming any audio files. Upload your file and get started immediately!"
    }, {
        "itemprop": "image",
        "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
    }, {
        "property": "og:type",
        "content": "website"
    }, {
        "property": "og:title",
        "content": "Audio Trimmer | MP3 Cutter | Audio Cutter"
    }, {
        "property": "og:site_name",
        "content": "Audio AI Dynamics"
    }, {
        "property": "og:url",
        "content": "https://audioaidynamics.com/audio-trimmer"
    }, {
        "property": "og:description",
        "content": "Audio Trimmer - free online tool for cutting and trimming any audio files. Upload your file and get started immediately!"
    }, {
        "property": "og:image",
        "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
    }, {
        "name": "twitter:title",
        "content": "Audio Trimmer | MP3 Cutter | Audio Cutter"
    }, {
        "name": "twitter:card",
        "content": "summary_large_image"
    }, {
        "name": "twitter:description",
        "content": "Audio Trimmer - free online tool for cutting and trimming any audio files. Upload your file and get started immediately!"
    }, {
        "name": "twitter:image",
        "content": "https://audioaidynamics.com/aaid-logo-tw.jpg"
    }]

export const VoiceRecorderMetaTags = [
    {
        "name": 'keywords',
        "content": "voice recorder,audio recorder,online voice recorder,online audio recorder,recorder,record a voice,podcast record"
    }, {
        "name": "description",
        "content": "Record a voice or sound with an online voice recorder, save your audio and download in any of the possible export formats for free."
    }, {
        "itemprop": "name",
        "content": "Voice Recorder | Audio Recorder | Online Audio Recorder"
    }, {
        "itemprop": "description",
        "content": "Record a voice or sound with an online voice recorder, save your audio and download in any of the possible export formats for free."
    }, {
        "itemprop": "image",
        "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
    }, {
        "property": "og:type",
        "content": "website"
    }, {
        "property": "og:title",
        "content": "Voice Recorder | Audio Recorder | Online Audio Recorder"
    }, {
        "property": "og:site_name",
        "content": "Audio AI Dynamics"
    }, {
        "property": "og:url",
        "content": "https://audioaidynamics.com/voice-recorder"
    }, {
        "property": "og:description",
        "content": "Record a voice or audio with an online voice recorder, save your audio and download in any of the possible export formats for free."
    }, {
        "property": "og:image",
        "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
    }, {
        "name": "twitter:title",
        "content": "Voice Recorder | Audio Recorder | Online Audio Recorder"
    }, {
        "name": "twitter:card",
        "content": "summary_large_image"
    }, {
        "name": "twitter:description",
        "content": "Record a voice or audio with an online voice recorder, save your audio and download in any of the possible export formats for free."
    }, {
        "name": "twitter:image",
        "content": "https://audioaidynamics.com/aaid-logo-tw.jpg"
    }]
export const DonateMetaTags = [
    {
        "name": 'keywords',
        "content": "donate,donation,support,crypto"
    }, {
        "name": "description",
        "content": "Support Audio AI Dynamics by donating"
    }, {
        "itemprop": "name",
        "content": "Audio AI Dynamics - Donate"
    }, {
        "itemprop": "description",
        "content": "Support Audio AI Dynamics by donating"
    }, {
        "itemprop": "image",
        "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
    }, {
        "property": "og:type",
        "content": "website"
    }, {
        "property": "og:title",
        "content": "Audio AI Dynamics - Donate"
    }, {
        "property": "og:site_name",
        "content": "Audio AI Dynamics"
    }, {
        "property": "og:url",
        "content": "https://audioaidynamics.com/donate"
    }, {
        "property": "og:description",
        "content": "Support Audio AI Dynamics by donating"
    }, {
        "property": "og:image",
        "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
    }, {
        "name": "twitter:title",
        "content": "Audio AI Dynamics - Donate"
    }, {
        "name": "twitter:card",
        "content": "summary_large_image"
    }, {
        "name": "twitter:description",
        "content": "Support Audio AI Dynamics by donating"
    }, {
        "name": "twitter:image",
        "content": "https://audioaidynamics.com/aaid-logo-tw.jpg"
    }]
export const GenreFinderTags = [
    {
        "name": 'keywords',
        "content": "song genre finder,music genre finder,genre of a song,genre finder,genre detector,Song genre finder,song genre identifier,find genre of a song,music genre,audio genre"
    }, {
        "name": "description",
        "content": "Discover your music genre with free online real-time Music Genre Finder"
    }, {
        "itemprop": "name",
        "content": "Music Genre | Music Genre Finder | Song Genre Finder"
    }, {
        "itemprop": "description",
        "content": "Discover your music genre with free online real-time Music Genre Finder"
    }, {
        "itemprop": "image",
        "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
    }, {
        "property": "og:type",
        "content": "website"
    }, {
        "property": "og:title",
        "content": "Music Genre | Music Genre Finder | Song Genre Finder"
    }, {
        "property": "og:site_name",
        "content": "Audio AI Dynamics"
    }, {
        "property": "og:url",
        "content": "https://audioaidynamics.com/genre-finder"
    }, {
        "property": "og:description",
        "content": "Discover your music genre with free online real-time Music Genre Finder"
    }, {
        "property": "og:image",
        "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
    }, {
        "name": "twitter:title",
        "content": "Music Genre | Music Genre Finder | Song Genre Finder"
    }, {
        "name": "twitter:card",
        "content": "summary_large_image"
    }, {
        "name": "twitter:description",
        "content": "Discover your music genre with free online real-time Music Genre Finder"
    }, {
        "name": "twitter:image",
        "content": "https://audioaidynamics.com/aaid-logo-tw.jpg"
    }]

export const PrivacyPolicyTags = [
    {
        "name": 'keywords',
        "content": "privacy policy,aaid,audioaidynamics,company privacy policy,website privacy policy, audio ai dynamics"
    }, {
        "name": "description",
        "content": "Privacy Policy"
    }, {
        "itemprop": "name",
        "content": "Audio AI Dynamics - Privacy Policy"
    }, {
        "itemprop": "description",
        "content": "Audio AI Dynamics Privacy Policy"
    }, {
        "itemprop": "image",
        "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
    }, {
        "property": "og:type",
        "content": "website"
    }, {
        "property": "og:title",
        "content": "Audio AI Dynamics - Privacy Policy"
    }, {
        "property": "og:site_name",
        "content": "Audio AI Dynamics Privacy Policy"
    }, {
        "property": "og:url",
        "content": "https://audioaidynamics.com/privacy-policy"
    }, {
        "property": "og:description",
        "content": "AAID Privacy Policy"
    }, {
        "property": "og:image",
        "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
    }, {
        "name": "twitter:title",
        "content": "Audio AI Dynamics - Privacy Policy"
    }, {
        "name": "twitter:card",
        "content": "summary_large_image"
    }, {
        "name": "twitter:description",
        "content": "Privacy Policy"
    }, {
        "name": "twitter:image",
        "content": "https://audioaidynamics.com/aaid-logo-tw.jpg"
    }]
export const RoadmapTags = [
    {
        "name": 'keywords',
        "content": "song genre finder,music genre finder,music analyzer,voice recorder,audio recorder,audio trimmer,online metronome,bpm tapper,bpm key finder,hpcp chroma"
    }, {
        "name": "description",
        "content": "Audio AI Dynamics Roadmap"
    }, {
        "itemprop": "name",
        "content": "Audio AI Dynamics - Roadmap"
    }, {
        "itemprop": "description",
        "content": "AAID Roadmap"
    }, {
        "itemprop": "image",
        "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
    }, {
        "property": "og:type",
        "content": "website"
    }, {
        "property": "og:title",
        "content": "Audio AI Dynamics Roadmap"
    }, {
        "property": "og:site_name",
        "content": "Audio AI Dynamics"
    }, {
        "property": "og:url",
        "content": "https://audioaidynamics.com/roadmap"
    }, {
        "property": "og:description",
        "content": "Audio AI Dynamics Roadmap"
    }, {
        "property": "og:image",
        "content": "https://audioaidynamics.com/aaid-logo-og.jpg"
    }, {
        "name": "twitter:title",
        "content": "Audio AI Dynamics - Roadmap"
    }, {
        "name": "twitter:card",
        "content": "summary_large_image"
    }, {
        "name": "twitter:description",
        "content": "Audio AI Dynamics Roadmap"
    }, {
        "name": "twitter:image",
        "content": "https://audioaidynamics.com/aaid-logo-tw.jpg"
    }]
